
import { defineComponent } from 'vue'
import PageSingle from '@/components/layout/PageSingle.vue'
import TmButton from '@/components/shared/TmButton.vue'
import SampleTermsContent from '@/components/pages/sample/SampleTermsContent.vue'

export default defineComponent({
  components: {
    SampleTermsContent,
    TmButton,
    PageSingle,
  },
})


import { computed, defineComponent } from 'vue'
import type { PropType } from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  components: {
    TmScrollbar,
    TmButton,
    TmAlert,
  },
  props: {
    pageTitle: {
      type: String,
    },
    title: {
      type: String,
    },
    size: {
      type: String as PropType<'' | 'small'>,
    },
    hideNavigation: {
      type: Boolean,
    },
    blue: {
      type: Boolean,
    },
  },
  setup(props) {
    const kebabSize = computed(() => kebabCase(props.size))

    return {
      kebabSize,
    }
  },
})

<template>
  <div class="sample-terms-content">
    <div class="headline-18">
      Messaging Terms & Conditions
    </div>
    Textmagic | Salisbury House Station Road Cambridge Cambridgeshire GB CB1 2LA
    <br><br>
    <div class="headline-18">
      General
    </div>
    When you opt-in to the service, we will send you a message to confirm your signup.
    <br><br>
    By opting into messages, you agree to receive recurring automated marketing and informational text messages from Textmagic. Automated messages may be sent using an automatic telephone dialing system to the mobile telephone number you provided when signing up or any other number that you designate.
    <br><br>
    Message frequency varies, and additional mobile messages may be sent periodically based on your interaction with Textmagic. Textmagic reserves the right to alter the frequency of messages sent at any time to increase or decrease the total number of sent messages. Textmagic also reserves the right to change the short code or phone number where messages are sent.
    <br><br>
    Message and data rates may apply. If you have any questions about your text plan or data plan, it is best to contact your wireless provider. Your wireless provider is not liable for delayed or undelivered messages.
    <br><br>
    Your consent to receive marketing messages is not a condition of purchase.
    <br><br>
    <div class="headline-18">
      Carriers
    </div>
    Carriers are not liable for delayed or undelivered messages.
    <br><br>
    <div class="headline-18">
      Cancellation
    </div>
    You can cancel any time by texting "STOP". After you send the SMS message "STOP", we will send you a message to confirm that you have been unsubscribed and no more messages will be sent. If you would like to receive messages from Textmagic again, just sign up as you did the first time and Textmagic will start sending messages to you again.
    <br><br>
    <div class="headline-18">
      Info
    </div>
    Text "HELP" at any time and we will respond with instructions on how to unsubscribe. For support regarding our services, email us at support@textmagic.com.
    <br><br>
    <div class="headline-18">
      Transfer of Number
    </div>
    You agree that before changing your mobile number or transferring your mobile number to another individual, you will either reply “STOP” from the original number or notify us of your old number at support@textmagic.com. The duty to inform us based on the above events is a condition of using this service to receive messages.
    <br><br>
    <div class="headline-18">
      Privacy
    </div>
    If you have any questions about your data or our privacy practices, please visit our http://eepurl.com/iUy4U2.
    <br><br>
    <div class="headline-18">
      Messaging Terms Changes
    </div>
    We reserve the right to change or terminate our messaging program at any time. We also reserve the right to update these Messaging Terms at any time. Such changes will be effective immediately upon posting. Your continued enrollment following such changes shall constitute your acceptance of such changes.
  </div>
</template>

<style lang="scss" scoped>
.sample-terms-content {
  line-height: 1.2;
  font-size: $sm-font;
}
</style>
